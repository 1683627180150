import { IsomorphicGA } from "./IsomorphicGA";

const isomorphicGA = new IsomorphicGA();

export const analytics = {
  track(name: string, params?: object) {
    isomorphicGA.event(name, params);
  },

  page(path?: string, title?: string) {
    const hitType = "pageview";
    isomorphicGA.send({ hitType, page: path ?? location.pathname, title: title ?? document.title });
  },
};
