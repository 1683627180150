import ReactGA from "react-ga4";
import { getConfig } from "@portal-frontend-ssr/config";

const { GOOGLE_TAG_MANAGER_KEY } = getConfig();

export class IsomorphicGA {
  private isBrowserEnvironment: boolean;

  constructor() {
    this.isBrowserEnvironment = typeof document !== "undefined";

    if (this.isBrowserEnvironment) {
      ReactGA.initialize(GOOGLE_TAG_MANAGER_KEY);
    }
  }

  event(...args: Parameters<typeof ReactGA.event>) {
    if (!this.isBrowserEnvironment) return;
    ReactGA.event(...args);
  }

  send(...args: Parameters<typeof ReactGA.send>) {
    if (!this.isBrowserEnvironment) return;
    ReactGA.send(...args);
  }
}
